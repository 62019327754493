<script setup lang="ts">
const props = defineProps({
  adUnitId: {
    type: String,
    required: true,
  },
  adUnitPath: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    required: true,
  },
  height: {
    type: Number,
    required: true,
  },
  targeting: {
    type: Object,
    default: () => ({}),
  },
})

const { $loadGoogleAdManager } = useNuxtApp()
let currentSlot = null

onMounted(async () => {
  try {
    await $loadGoogleAdManager()
    initAd()
  } catch (error) {
    console.error('Error initializing ad:', error)
  }
})

onUnmounted(() => {
  window.googletag?.cmd.push(() => {
    if (currentSlot) {
      googletag.destroySlots([currentSlot])
    }
  })
})

const initAd = () => {
  window.googletag.cmd.push(() => {
    // Define the ad slot
    const adSlot = googletag
      .defineSlot(props.adUnitPath, [props.width, props.height], props.adUnitId)
      .addService(googletag.pubads())

    // Add targeting parameters if any
    Object.entries(props.targeting).forEach(([key, value]) => {
      adSlot.setTargeting(key, value)
    })

    currentSlot = adSlot

    // Enable additional features
    googletag.pubads().enableSingleRequest()
    googletag.pubads().collapseEmptyDivs()

    googletag.enableServices()
    googletag.display(props.adUnitId)
  })
}
</script>

<template>
  <div :id="adUnitId" :style="{ minWidth: `${width}px`, minHeight: `${height}px` }" />
</template>

<style scoped></style>
